import { paths } from "@library/project/paths";

import "./Main.scss";

const Main = () => {
  return (
    <div className="main">
      <img src="/images/landing-page.jpg" className="landing desktop d-xs-n d-xl-b" />
      <img src="/images/landing-page-mobile.jpg" className="landing mobile d-xs-b d-xl-n" />
      <img src="/images/info-desktop.png" className="info d-xs-n d-xl-b" />
      <img src="/images/info-mobile.png" className="info d-xs-b d-xl-n" />
    </div>
  );
};

export default {
  path: paths.main,
  element: <Main />,
  children: []
};
