import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import Router from "./app/router";

import "@library/frontend/scss/reset.scss";
import "@library/frontend/scss/root.scss";
import "@library/frontend/scss/utils.scss";
import "@library/frontend/scss/typography.scss";
import "@library/frontend/scss/layers.scss";
import "@library/frontend/scss/responsive.scss";
import "@library/frontend/scss/design-system.scss";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

ReactRoot.render(
  <HelmetProvider>
    <Router />
  </HelmetProvider>
);
