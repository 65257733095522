import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./Main/Main";

const router = createBrowserRouter([Main]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
